<template>
  <!-- 添加图标 -->
  <el-dialog
    :title="addIconDialogVisible.title"
    :visible.sync="addIconDialogVisible.status"
    destroy-on-close
    width="21.6vw"
  >
    <el-form
      class="form"
      :model="form"
      :rules="materialLibraryFormRules"
      ref="ruleFormRef"
      label-position="left"
      label-width="5.4vw"
    >
      <el-form-item label="图标名称：" prop="name">
        <el-input v-model.trim="form.name" maxlength="20" show-word-limit />
      </el-form-item>
      <el-form-item
        label="选择分类："
        label-position="left"
        label-width="6.3vw"
        style="margin-left: -0.9vw"
        prop="categoryId"
      >
        <el-select v-model="form.categoryId" placeholder="默认为系统分类">
          <el-option
            v-for="item in categoryOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        :label="
          addIconDialogVisible.title === '上传图标'
            ? '上传图标：'
            : '编辑图标：'
        "
        label-position="left"
        label-width="5.4vw"
        prop="url"
      >
        <UploadPic
          :editPicData="addIconDialogVisible"
          @url="handleUrl"
          @uploadPic="uploadPic"
          ref="uploadPic"
        />
      </el-form-item>
      <!-- 底部按钮 -->
      <el-form-item>
        <div class="form-btn-group">
          <Button class="form-btn cancel" @click="addIconDialogVisible = false"
            >取消</Button
          >
          <Button class="form-btn confirm" @click="confirm">确定</Button>
        </div>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import Button from 'c/basic/Button';
import UploadPic from 'c/basic/UploadPic';
// 引入表单校验规则
import { userRulesMixin } from 'p/mixin.js';
export default {
  name: 'AddIcon',
  mixins: [userRulesMixin],
  components: {
    Button,
    UploadPic
  },
  data() {
    return {
      // 表单
      form: {
        name: '',
        url: '',
        categoryId: ''
      },
      // 分类数据
      categoryOptions: []
    };
  },
  computed: {
    addIconDialogVisible: {
      get() {
        return this.$store.state.materialLibrary.addIconDialogVisible;
      },
      set(val) {
        this.$store.commit('materialLibrary/setAddIconDialogVisible', val);
      }
    }
  },
  methods: {
    // 获取图片地址
    handleUrl(url) {
      this.form.url = url;
    },
    // 获取分类
    getIconList() {
      const params = {
        type: 1,
        order: 1,
        selectSystem: 0
      };
      this.$service.materialLibrary
        .styleResourceCategoryAll(params)
        .then(res => {
          if (res.data.status === 200) {
            this.categoryOptions = res.data.data;
            if (this.form.categoryId) return;
            this.form.categoryId = res.data.data[0].id;
          }
        });
    },
    // 提交到组件校验
    confirm() {
      this.$refs.uploadPic.finish();
    },
    // 上传图片
    uploadPic() {
      this.$refs.ruleFormRef.validate(valid => {
        if (valid) {
          let title =
            this.addIconDialogVisible.title == '上传图标'
              ? 'styleIconAdd'
              : 'styleIconEidt';
          const params = this.form;
          this.$service.materialLibrary[title](params).then(res => {
            if (res.data.status === 200) {
              this.$message.success(res.data.msg);
              this.$emit('refresh');
              this.$store.commit('materialLibrary/setAddIconDialogVisible', '');
            }
          });
        }
      });
    }
  },
  watch: {
    addIconDialogVisible: {
      immediate: true,
      handler(data) {
        if (data.data) {
          this.form = JSON.parse(JSON.stringify(data.data));
          this.getIconList();
        } else if (data.status) {
          this.form = {
            id: '',
            name: '',
            url: '',
            categoryId: JSON.parse(JSON.stringify(data.categoryId))
          };
          this.getIconList();
        }
      }
    }
  }
};
</script>

<style lang="less" scoped>
/deep/.el-form-item__label {
  padding: 0 !important;
}
</style>
